import React from "react"
import { Link } from "gatsby"

import './style.scss'

const renderPreviousLink = ({ hasPreviousPage, currentPage, archivePath }) => {
  let previousLink = null

  if (2 === currentPage) {
    previousLink = archivePath
  } else if (2 < currentPage) {
    previousLink = `${archivePath}${currentPage - 1}`
  }

  if (hasPreviousPage) {
    return (
      <Link className={"pagination-previous prev page-numbers"} to={previousLink}>
        <span aria-hidden="true">←</span>
        <span className="nav-prev-text">
          Previous Page
        </span>
      </Link>
    )
  } else {
    return (
      <span className={"prev page-numbers placeholder pagination-previous"} aria-hidden="true" disabled>
        <span aria-hidden="true">←</span>
        <span className="nav-prev-text">
          Previous Page
        </span>
      </span>
    )
  }
}

const renderNextLink = ({ hasNextPage, currentPage, archivePath }) => {
  if (hasNextPage) {
    return (
      <Link
        className={"pagination-next next page-numbers"}
        to={`${archivePath}${currentPage + 1}`}
      >
        <span className="nav-next-text">
          Next Page
        </span>
        {"→"}
      </Link>
    )
  } else {
    return (
      <span className={"next page-numbers placeholder pagination-next"} aria-hidden="true" disabled>
        <span className="nav-next-text">
          Next Page
        </span>
        {"→"}
      </span>
    )
  }
}

const renderPagesInBetween = ({ currentPage, pageCount, archivePath }) => {
  const pageNumber = (page, isCurrent, isFirst) => {
    if (isCurrent) {
      return {
        tag: "span",
        children: page,
        className: "page-numbers pagination-link is-current",
      }
    }

    const to = isFirst ? archivePath : `${archivePath}${page}`

    return {
      tag: Link,
      children: page,
      to: to,
      className: "pagination-link"
    }
  }

  const dots = {
    tag: "span",
    children: "…",
    className: "page-numbers dots pagination-ellipsis",
  }

  let elementsToRender = []

  if (pageCount > 5) {
    // current is 1 - 3: show all on left side and dots for right
    if (currentPage < 4) {
      for (let i = 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }
      elementsToRender.push(dots) // dots in between
      elementsToRender.push(pageNumber(pageCount, false, false)) // last page
    }
    // if on of the last 3
    else if (currentPage >= pageCount - 2) {
      elementsToRender.push(pageNumber(1, false, true)) // last page
      elementsToRender.push(dots)

      for (let i = currentPage - 1; i < pageCount + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }
    } else {
      elementsToRender.push(pageNumber(1, false, true)) // last page
      elementsToRender.push(dots)

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }

      elementsToRender.push(dots)
      elementsToRender.push(pageNumber(pageCount, false, false)) // last page
    }
  } else {
    for (let i = 1; i < pageCount + 1; i++) {
      elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
    }
  }

  return elementsToRender.map(({ tag: Tag, children, ...props }, index) => (
    <Tag key={index} {...props}>
      {children}
    </Tag>
  ))
}

const ArchivePagination = ({
  hasPreviousPage,
  hasNextPage,
  currentPage,
  pageCount,
  archivePath,
}) => {
  if (pageCount <= 1) {
    return null
  }

  return (
    <div className="container pagination-wrapper section-inner">

      <nav
        className="navigation pagination is-rounded is-centered"
        role="navigation"
        aria-label="pagination"
      >
          {renderPreviousLink({ hasPreviousPage, currentPage, archivePath })}
          {renderNextLink({ hasNextPage, currentPage, archivePath })}
        <div className="pagination-list nav-links">
          {renderPagesInBetween({ currentPage, pageCount, archivePath })}
        </div>
      </nav>
    </div>
  )
}

export default ArchivePagination