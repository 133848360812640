import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import CampaignPreview from "../components/campaignPreview"
import ArchivePagination from "../components/archivePagination"

import '../assets/Scss/templates/archives.scss'

const CampaignsArchive = props => {
  const {
    data: {
      allWpCauses: { nodes, pageInfo },
    },
    pageContext: { archivePath, uri },
  } = props

  return (
    <Layout bodyClass="campaigns blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <Seo
        title="Campaigns"
        description="Arab Women Rights Advocats Campaigns!"
        uri={uri}
      />

      <section className="container -columns is-multiline">
        {nodes &&
          nodes.map((cause, index) => {
            return (
              <div className="-column" key={index}>
                <CampaignPreview
                  key={index}
                  post={cause}
                  isLast={index === nodes.length - 1}
                />
              </div>
            )
          })}
      </section>

      <ArchivePagination {...pageInfo} archivePath={archivePath} />
    </Layout>
  )
}

export const query = graphql`
  query CampaignsPage(
    $offset: Int!
    $perPage: Int!
  ) {
    allWpCauses(
      limit: $perPage
      skip: $offset
      filter: {
        languages: { nodes: { elemMatch: { name: { eq: "English" } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...CausesPreview
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`

export default CampaignsArchive
